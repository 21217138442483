export enum APIType {
  BetRadarResults,
  BetRadarStatistics,
  CMS,
  Guardian,
  F2PGuardian,
  Local,
  Platform,
  SportsbookBetsearch,
  SportsbookBetting,
  // Migration of betting APIs to new services happens in two stages
  // We need to be able to rollback to old services on seperate stages if needed
  SportsbookBetting2,
  SportsbookCashout,
  SportsbookFeed,
  SportsbookVslFeed,
  SportsbookOffer,
  Virtuals,
  VirtualsBetSearch,
  VirtualsFeed,
  KMJackpots,
  JackpotBets,
  StrapiCms,
  Website,
  Engagement,
  SuperPicksValidationDomain,
  Casino,
  BFFGateway,
  BFFSportsbook,
  UserService,
  SignalrNotifications,
}

export class APISettings {
  contentType: string;
  playSource: number;
  noAuthToken: boolean;
  forceAuthToken: string;
  inBehalfOf: string;
  sendActivitySource: boolean;
  timeout: number;
  adjustId?: string;
  idfa?: string;
  gpsAdid?: string;

  constructor(init?: Partial<APISettings>) {
    Object.assign(this, init);
  }
}

export class APIResult<T> {
  success = false;
  data: T;
  error: string;
}
