import { BetCoupon, BetCouponGlobalVariable, Dictionary, Selection } from 'clientside-coupon';
import { BookBetModel } from 'src/app/shared/models/book-bet.model';
import { CouponReceiptContentModel, CouponReceiptPhoneVerificationContentModel } from 'src/app/shared/models/coupon-receipt.model';
import { QuickBetMarketModel } from 'src/app/shared/models/quick-bet.model';
import { SportModel } from 'src/app/shared/models/sport.model';
import { FlexicutComputationResponse } from '@kingmakers-tech/flexicut-csc';
import { MatchModel, TournamentModel, QuicklinkModel } from './sport.model';

export interface CouponState {
  betslipScrollTop: number;
  bookedBetData: BookBetModel;
  bookedCoupons: BookedCoupon[];
  correctScoreOddsMatrix: any;
  couponData: BetCoupon;
  couponInitialized: boolean;
  couponReceiptContent: CouponReceiptContentModel;
  couponReceiptPhoneVerificationContent: CouponReceiptPhoneVerificationContentModel;
  couponSettings: CouponSettings;
  defaultCouponStake: DefaultCouponStake;
  editCouponData: MatchModel;
  expiredEvents: ExpiredEventsModel;
  flexicutSelectedOption: FlexicutOptionModel;
  flexicutResponse: FlexicutComputationResponse;
  globalVariables: BetCouponGlobalVariable;
  groupingsTabSelected: CouponGroupingType;
  bestSellerCoupons: BestSellerModel[];
  emptyBetslipQuicklinks: QuicklinkModel[];
  invalidFreebetSelections: number[];
  invalidFlexicutSelections: number[];
  isFlexicutServiceInitialised: boolean;
  isLastPlacedCouponInEvaluation: boolean;
  lastPlacedCoupon: BetCoupon;
  lastPlacedCouponCode: string;
  marketExceptions: Dictionary<number, number[]>;
  oddChanges: OddChanges[];
  previousPagePath: string;
  selectionMarketMatches: MarketMatchMap[];
  selections: TournamentModel[];
  groupedSelections: SportModel[];
  ui: CouponUIState;
}

export class CouponUIState {
  showCoupon: boolean;
  showQuickCoupon: boolean;
  couponForEdit: {
    matchId: number;
    marketTypeId: number;
  };

  constructor(init: Partial<CouponUIState>) {
    Object.assign(this, init);
  }
}

export enum CouponGroupingType {
  Multiple = 1,
  Split = 2,
  Singles = 3,
  Combination = 4,
}

export class GroupingTabsVisibleModel {
  multiple: boolean = false;
  split: boolean = false;
  singles: boolean = false;
  combination: boolean = false;

  constructor(init?: Partial<GroupingTabsVisibleModel>) {
    Object.assign(this, init);
  }
}

export class CouponSettings {
  lastAllowOddChanges: boolean = true;
  allowOddChanges: boolean = true;
  allowCompetitionGrouping: boolean = false;
  allowTransfer: boolean = false;
  transferUserId: string = undefined;
  bookedCouponCode: string;

  constructor(init?: Partial<CouponSettings>) {
    Object.assign(this, init);
  }
}

export class DefaultCouponStake {
  allowSaveDefault: boolean = false;
  defaultStake: number;

  constructor(init?: Partial<DefaultCouponStake>) {
    Object.assign(this, init);
  }
}

export class OddChanges {
  oddId: number;
  initialOddValue: number;
  latestOddValue: number;
  valueChanged: boolean;

  constructor(init?: Partial<OddChanges>) {
    Object.assign(this, init);
  }
}

export class CouponOddsModel {
  isLive: boolean;
  matchId: number;
  marketId: number;
  marketTypeId?: number;
  selectionId: number;
  selectionValue: number;

  constructor(init?: Partial<CouponOddsModel>) {
    Object.assign(this, init);
  }
}

export interface BestSellerModel {
  bestSellerName: string;
  idBestSeller: number;
  potentialWinnings: number;
  idCouponType: number;
  odds: BestSellerOddModel[];
  stake: number;
  totalOdds: number;
  unboostedTotalOdds: number;
  bestSellerStartDate: string;
  bookedCouponCode: string;
  bookingCount: number;
}
export class BestSellerOddModel {
  EventName: string;
  HomeTeam: string;
  AwayTeam: string;
  MarketName: string;
  SelectionName: string;
  OddValue: number;
  IDSelection: number;
  UnboostedOddValue: number;
  IsBetBuilder: boolean;

  constructor(init?: Partial<BestSellerOddModel>) {
    Object.assign(this, init);
  }

  get isBoosted(): boolean {
    return this.UnboostedOddValue !== undefined && this.UnboostedOddValue !== null && this.UnboostedOddValue < this.OddValue;
  }
}

export class ExpiredEventsModel {
  availableEventCount: number;
  originalEventCount: number;
  expiredEvents: ExpiredEvents[];
  bookedBetId: string;

  constructor(init?: Partial<ExpiredEventsModel>) {
    Object.assign(this, init);
  }
}

export class FlexicutOptionModel {
  optionId: string;
  cutNumber: number;
}

export class ExpiredEvents {
  eventId: number;
  smartBetCode: number;
  eventName: string;
  eventDate: Date;
  eventCategory: string;
  marketName: string;
  marketTypeId: number;
  selectionName: string;
  oddValue: number;
  isBetBuilder: boolean = false;

  constructor(init?: Partial<ExpiredEvents>) {
    Object.assign(this, init);
  }
}

export class BookedCoupon {
  couponCode: string;
  date: string;

  constructor(init?: Partial<BookedCoupon>) {
    Object.assign(this, init);
  }
}

export class MarketMatchMap {
  marketId: number;
  matchIds: number[];

  constructor(init?: Partial<MarketMatchMap>) {
    Object.assign(this, init);
  }
}

export class OddModel {
  id: number;
  value: number;
  unboostedValue: number | undefined | null;
  // Spread Value may either be a number (0, 1, 2...)
  // or a string (0:1, 0:2, 2:1...)
  spreadValue: number;
  spreadValueDisplay: number | string = 0;
  sportId: number;
  sportName: string;
  categoryId: number;
  categoryName: string;
  tournamentId: number;
  tournamentName: string;
  matchId: number;
  matchName: string;
  matchDate: Date;
  marketId: number;
  marketTypeId: number;
  marketName: string;
  smartCode: number;
  eventCategory: string;
  combinability: number;
  selectionId: number;
  selectionTypeId: number;
  selectionName: string;
  selectionNames: string[];
  selectionDescription: string;
  incompatibleEvents: number[] = [];
  enabled: boolean = true;
  selected: boolean = false;
  isLocked: boolean = false;
  isExpired: boolean = false;
  groupingType: number;
  isBetBuilder: boolean;
  showSelectionName: boolean = false;

  constructor(init: Partial<OddModel>) {
    Object.assign(this, init);
  }

  get isBoosted(): boolean {
    return this.unboostedValue !== undefined && this.unboostedValue !== null && this.unboostedValue < this.value;
  }

  static fromQuickBetMarketModel(init: QuickBetMarketModel): OddModel {
    return new OddModel({
      id: init.matchOddsID,
      smartCode: parseInt(init.smartCode, 10),
      marketId: init.marketID,
      marketName: init.marketName,
      marketTypeId: init.marketTypeID,
      value: init.oddValue,
      unboostedValue: init.unboostedOddValue,
      selectionId: init.selectionID,
      selectionName: init.selectionName,
      spreadValue: init.spreadValue,
    });
  }

  toSelection(): Selection {
    const selection: Selection = new Selection();
    selection.categoryId = this.categoryId;
    selection.categoryName = this.categoryName;
    selection.eventCategory = this.eventCategory;
    selection.combinability = this.combinability;
    selection.marketId = this.marketId;
    selection.marketTypeId = this.marketTypeId;
    selection.marketName = this.marketName;
    selection.matchDate = this.matchDate;
    selection.matchId = this.matchId;
    selection.matchName = this.matchName;
    selection.smartBetCode = this.smartCode;
    selection.oddId = this.id;
    selection.oddValue = this.value;
    selection.unboostedOddValue = this.unboostedValue;
    selection.selectionTypeId = this.selectionId;
    selection.selectionName = this.selectionName;
    selection.sportId = this.sportId;
    selection.sportName = this.sportName;
    // For some reason, the specialValue is not bieng used to display spreads, even if the value is not a number.
    // So we need to accept any type into spreadValue
    selection.spreadValue = this.spreadValueDisplay as any;
    selection.specialValue = this.spreadValueDisplay as string;
    selection.tournamentId = this.tournamentId;
    selection.tournamentName = this.tournamentName;
    selection.incompatibleEvents = this.incompatibleEvents;
    selection.groupingType = this.groupingType;
    selection.isBetBuilder = this.isBetBuilder;
    return selection;
  }
}

export class BookedCouponModel {
  couponData: BetCoupon;
  bookedCouponCode: string;
  bookedCouponDate: string;

  constructor(init: Partial<BookedCouponModel>) {
    Object.assign(this, init);
  }
}

export class PostCouponResponse {
  validateCouponSuccess: boolean = false;
  updateCouponSuccess: boolean = false;
  updateStatusCode: number = -1;
  postCouponSuccess: boolean = false;
  postStatusCode: number = -1;
  couponId: number;
  couponCode: string;

  constructor(init: Partial<PostCouponResponse>) {
    Object.assign(this, init);
  }
}

export interface OddsByMarket {
  marketId: number;
  marketName: string;
  odds: OddModel[];
}

export interface OddFilter {
  filterName: string;
  lessOddRange: number;
  greaterOddRange: number;
}
