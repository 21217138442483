<div class="event-tournament-details">
  <div class="tournament-details" [class.live-list-widget]="isLiveListWidget">
    <span class="material-icons-outlined chart-icon" aria-hidden="true"
      [class.active]="event.id === (statisticsQuery.selectedEventId$ | async) && statisticsQuery.expandedStatistics$ | async"
      (click)="statisticsService.statisticsBetRadar(event.externalId, event.id)"
      *ngIf="enableStatistics && event.externalId !== 0 && event.eventCategory !== 'L' && event.sportId !== appConfigService.get('sports').footballGoId">
      {{event.id === (statisticsQuery.selectedEventId$ | async) ? 'close' : 'bar_chart'}}
    </span>

    <!-- Current Status and time for Live Events -->
    <div class="match-status" *ngIf="!isLiveListWidget && event.eventCategory === 'L'">
      <span class="icon-running-live">
        <span class="inner-circle"></span>
      </span>
      <div class="status">{{ liveQuery.getLiveMatchStatusLabel(event) }}</div>
      <div class="time" *ngIf="event.sportId !== 1 && event.matchTime">
        <span>{{ event.matchTime }}</span>
        <span class="blink">'</span>
      </div>
    </div>
    <div class="match-status" *ngIf="isLiveListWidget && event.eventCategory === 'L'">
      <div class="status"
        [class.space]="event.matchTime"
        *ngIf="((event.sportId === 1 || event.sportId === 8) && !event.matchTime) || (event.sportId !== 1 && event.sportId !== 8)">
        {{ liveQuery.getLiveMatchStatusAbbrLabel(event) }}
      </div>
      <div class="time" *ngIf="event.matchTime">
        <span>{{ event.matchTime }}'</span>
      </div>
    </div>

    <div class="location" (click)="navigateToEvent(event.id, event.eventCategory === 'L')">
      <div class="category-tournament">{{ event.categoryName }} - {{ event.tournamentName }}</div>
    </div>
  </div>
  <div class="odds-count" *ngIf="showOddCount" (click)="navigateToMatch(event.id, event.name, event.eventCategory === 'L')">
    <ng-container *ngIf="event?.oddCount">
      +{{ event.oddCount }}
    </ng-container>
  </div>
</div>
